class SliderPanelCards {
  constructor() {
    const swiper = new Swiper(".swiper-panel-cards", {
      centeredSlides: true,
      loop: true,
      spaceBetween: 12,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
  }
}
export { SliderPanelCards };
