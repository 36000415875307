import anime from "../libs/anime";
import globalVar from "../utils/globalVar";

class Nav {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
      checkHeaderColor();
      checkHoverMenu();
    };

    let initCache = () => {
      cache.nav = document.querySelector(".nav");
      cache.navmobileBtn = document.querySelector(".nav_mobile");
      cache.header = document.querySelector(".site-header");
      cache.links = document.querySelectorAll(".primary-menu > .menu-item");
      cache.subLinks = document.querySelectorAll(".sub-menu > .menu-item");
      cache.btnDropdowns = document.querySelectorAll(
        ".menu-item-has-children > .btn-dropdown"
      );
      cache.section = document.querySelector(".module-01-header.noimg");
      cache.darkMode = document.querySelector(".dark-mode");
    };

    let registerEvents = () => {
      cache.navmobileBtn.addEventListener("click", toggleNav);

      cache.btnDropdowns.forEach((btn) => {
        btn.addEventListener("click", function(event) {
          event.stopPropagation();
          const parentLi = btn.closest(".menu-item");

          parentLi.classList.toggle("open");

          cache.links.forEach((link) => {
            if (link !== parentLi) {
              link.classList.remove("open");
            }
          });
        });
      });

      cache.navmobileBtn.addEventListener("click", function() {
        cache.nav.classList.toggle("active");
        cache.navmobileBtn.classList.toggle("active");
        cache.header.classList.toggle("active");
      });

      cache.subLinks.forEach((link) => {
        link.addEventListener("mouseover", addHoverClass);
        link.addEventListener("mouseleave", removeHoverClass);
      });

      window.addEventListener("resize", checkHoverMenu);
    };

    let checkHeaderColor = () => {
      if (cache.section || cache.darkMode) {
        cache.header.classList.add("dark");
      } else {
        cache.header.classList.remove("dark");
      }
    };

    let toggleNav = () => {
      if (cache.nav.classList.contains("active")) {
        closeNav();
      } else {
        openNav();
      }
    };

    let openNav = () => {
      cache.navmobileBtn.removeEventListener("click", openNav);
      cache.nav.style.display = "flex";
      document.querySelector("body").classList.add("overflow");
      document.querySelector("html").classList.add("overflow");
      anime({
        targets: cache.nav,
        opacity: [0, 1],
        translateX: [250, 0],
        duration: 400,
        easing: "easeOutQuad",
        complete: function() {
          cache.navmobileBtn.addEventListener("click", closeNav);
        },
      });
    };

    let closeNav = () => {
      cache.navmobileBtn.removeEventListener("click", closeNav);
      document.querySelector("body").classList.remove("overflow");
      document.querySelector("html").classList.remove("overflow");
      anime({
        targets: cache.nav,
        opacity: [1, 0],
        translateX: [0, 250],
        duration: 400,
        easing: "easeOutQuad",
        complete: function() {
          cache.navmobileBtn.addEventListener("click", openNav);
          cache.nav.style.display = "none";
        },
      });
    };

    let hoverMenu = () => {
      cache.header.addEventListener("mouseover", addHoverClass);
      cache.header.addEventListener(
        "mouseleave",
        removeHoverClassAndCloseSubMenus
      );
    };

    let addHoverClass = (event) => {
      if (event.currentTarget) {
        event.currentTarget.classList.add("active");
      }
    };

    let removeHoverClass = (event) => {
      if (event.currentTarget) {
        event.currentTarget.classList.remove("active");
      }
    };

    let removeHoverClassAndCloseSubMenus = (event) => {
      removeHoverClass(event);
      cache.links.forEach((link) => {
        link.classList.remove("open");
      });
    };

    let checkHoverMenu = () => {
      if (globalVar.desktop.matches) {
        hoverMenu();
      } else {
        resetMenuState();
        cache.header.removeEventListener("mouseover", addHoverClass);
        cache.header.removeEventListener(
          "mouseleave",
          removeHoverClassAndCloseSubMenus
        );

        if (cache.navmobileBtn.classList.contains("active")) {
          cache.navmobileBtn.click();
        }
      }
    };

    let resetMenuState = () => {
      cache.header.classList.remove("active");
      cache.links.forEach((link) => {
        link.classList.remove("open");
      });
    };

    initFunc();
  }
}

export { Nav };
