import globalVar from "../utils/globalVar";

class Module08 {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
      checkPaginationElement();
    };

    let initCache = () => {
      cache.pagination = document.querySelector(".swiper-pagination-bullets");
    };

    let registerEvents = () => {
      if (cache.pagination && globalVar.desktop.matches) {
        updatePaginationMargin();
      }

      window.addEventListener('resize', () => {
        if (cache.pagination) {
            updatePaginationMargin();
        }
    });
    };

    const updatePaginationMargin = () => {
      if (!globalVar.mobile.matches && !globalVar.mobile.matches) {
        const paginationWidth = cache.pagination.offsetWidth;
        cache.pagination.style.marginLeft = `calc(100% - 50% - ${paginationWidth}px)`;
      } else {
        cache.pagination.style.marginLeft = `0`;
      }
    };

    const checkPaginationElement = () => {
      const intervalId = setInterval(() => {
        initCache();
        if (cache.pagination && globalVar.desktop.matches) {
          updatePaginationMargin();
          clearInterval(intervalId); 
        }
      }, 100);
    };

    initFunc();
  }
}

export { Module08 };
