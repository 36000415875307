import globalVar from "../utils/globalVar";

class Bandeau {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      widthBandeau();
    };

    let initCache = () => {
      cache.logoSlide = document.querySelectorAll(".bandeau .logo-slide");
    };

    let widthBandeau = () => {
      let widthDesktop = 170;
      let widthMobile = 130;
      let margeDesktop = 80;
      let margeMobile = 64;

      cache.logoSlide.forEach((logoSlide) => {
        let images = logoSlide.children;
        let nb = images.length;
        if (globalVar.desktop.matches) {
          logoSlide.style.width = `${nb * widthDesktop + margeDesktop * nb}px`;
        } else {
          logoSlide.style.width = `${nb * widthMobile + margeMobile * nb}px`;
        }
      });
    };

    initFunc();
  }
}
export { Bandeau };
