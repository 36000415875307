class TestimonialSlider {
  constructor() {
    let cache = {};
    let currentIndex = 0;
    const intervalDuration = 5000;
    let intervalId;

    let initFunc = () => {
      initCache();
      registerEvents();
      startAutoSlide();
    };

    let initCache = () => {
      cache.cards = document.querySelectorAll(".slider-cards.dots .card");
      cache.descriptions = document.querySelectorAll(
        ".slider-cards.desc .description"
      );
    };

    let registerEvents = () => {
      if (document.readyState === "loading") {
        document.addEventListener("DOMContentLoaded", toggleCard);
      } else {
        toggleCard();
      }

      cache.cards.forEach((card) => {
        card.addEventListener("click", function() {
          clearInterval(intervalId);
          const cardNumber = this.getAttribute("data-card");
          activateCard(cardNumber);
          startAutoSlide();
        });
      });
    };

    let toggleCard = function() {
      if (cache.cards.length > 0) {
        cache.cards[0].classList.add("active");
      }
      if (cache.descriptions.length > 0) {
        cache.descriptions[0].classList.add("active");
      }
    };

    let activateCard = (cardNumber) => {
      cache.descriptions.forEach((description) => {
        description.classList.remove("active");
      });

      const targetDescription = document.querySelector(
        `.slider-cards.desc .description[data-card="${cardNumber}"]`
      );

      if (targetDescription) {
        targetDescription.classList.add("active");
      }

      cache.cards.forEach((card) => {
        card.classList.remove("active");
      });

      const targetCard = document.querySelector(
        `.slider-cards.dots .card[data-card="${cardNumber}"]`
      );

      if (targetCard) {
        targetCard.classList.add("active");
      }
    };

    let startAutoSlide = () => {
      intervalId = setInterval(() => {
        currentIndex = (currentIndex + 1) % cache.cards.length;
        const cardNumber = cache.cards[currentIndex].getAttribute("data-card");
        activateCard(cardNumber);
      }, intervalDuration);
    };

    initFunc();
  }
}
export { TestimonialSlider };
