class ScrollToSection {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    };

    let initCache = () => {
      cache.scrollButtons = document.querySelectorAll(".scrollToNextSection");
    };

    let registerEvents = () => {
      if (document.readyState === "loading") {
        document.addEventListener("DOMContentLoaded", scrollTo);
      } else {
        scrollTo();
      }
    };

    const scrollTo = () => {
      cache.scrollButtons.forEach((button) => {
        button.addEventListener("click", function(event) {
          event.preventDefault();
          const currentSection = this.closest("section");
          let nextSection = currentSection.nextElementSibling;
          while (nextSection && nextSection.tagName !== "SECTION") {
            nextSection = nextSection.nextElementSibling;
          }
          if (nextSection) {
            const nextSectionId = nextSection.getAttribute("id");
            this.setAttribute("href", `#${nextSectionId}`);
            nextSection.scrollIntoView({ behavior: "smooth" });
          }
        });
      });
    };

    initFunc();
  }
}
export { ScrollToSection };
