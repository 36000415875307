import anime from "../libs/anime";

class ScrollToTop {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    };

    let initCache = () => {
      cache.scrollToTop = document.getElementById("scrollToTop");
    };

    let registerEvents = () => {
      cache.scrollToTop.addEventListener("click", scrollUp);
    };

    const scrollUp = () => {
      anime({
        targets: "html, body",
        scrollTop: 0,
        duration: 1000,
        easing: "easeInOutQuad",
      });
    };

    initFunc();
  }
}
export { ScrollToTop };
