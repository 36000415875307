class Form {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    };

    let initCache = () => {
      cache.submit = document.querySelector(".wpcf7-submit");
    };

    let registerEvents = () => {
      if (document.readyState === "loading") {
        document.addEventListener("DOMContentLoaded", addWrapperBtn);
      } else {
        addWrapperBtn();
      }
    };

    let addWrapperBtn = () => {
      if (cache.submit) {
        const wrapper = document.createElement("div");
        wrapper.classList.add("submit-wrapper");

        cache.submit.parentNode.insertBefore(wrapper, cache.submit);
        wrapper.appendChild(cache.submit);
      }
    };

    initFunc();
  }
}
export { Form };
