import { Nav } from "./components/nav";
import { TitleSlice } from "./components/title-slice";
import { Bandeau } from "./components/bandeau";
import { PanelCards } from "./components/panel-cards";
import { ChangeStyle } from "./components/change-style";
import { Slider } from "./components/slider";
import { SliderSchema } from "./components/sliderSchema";
import { SliderPanelCards } from "./components/sliderPanelCards";
import { PopUp } from "./components/popup";
import { TestimonialSlider } from "./components/testimonial-slider";
import { RotatingTitle } from "./components/rotating-title";
import { ScrollToSection } from "./components/scrollToSection";
import { ScrollToTop } from "./components/scrollToTop";
import { Module08 } from "./modules/module-08-actualites-realisations";
import { Module13 } from "./modules/module-13-contact";
import { Module17 } from "./modules/module-17-individus";
import { Form } from "./components/form";

// import { DropDown } from './components/dropdown'

class App {
  constructor() {
    const nav = new Nav();

    if (document.querySelector("#scrollToTop")) {
      const scrollToTop = new ScrollToTop();
    }

    if (document.querySelector(".bandeau")) {
      const bandeau = new Bandeau();
    }

    if (document.querySelector(".module-01-header")) {
      const titleSlice = new TitleSlice();
    }

    if (document.querySelector(".module-03-panneau-cartes")) {
      const panelCards = new PanelCards();
    }

    if (document.querySelector(".module-04-cartes-outline")) {
      const changeStyle = new ChangeStyle();
    }

    if (document.querySelector(".module-08-actualites_realisations.slider")) {
      const module08 = new Module08();
    }

    if (document.querySelector(".module-13-contact")) {
      const module13 = new Module13();
    }

    if (document.querySelector(".module-17-individus")) {
      const module17 = new Module17();
    }

    if (document.querySelector(".schema-mobile")) {
      const sliderSchema = new SliderSchema();
    } else if (document.querySelector(".swiper")) {
      const slider = new Slider();
    }

    if (document.querySelector(".swiper-panel-cards")) {
      const sliderPanelCards = new SliderPanelCards();
    }

    if (document.querySelector(".popup")) {
      const popup = new PopUp();
    }

    if (document.querySelector(".btn-scrollTo")) {
      const scrollToSection = new ScrollToSection();
    }

    if (document.querySelector(".words-wrapper")) {
      const rotatingTitle = new RotatingTitle();
    }

    if (document.querySelector(".module-12-testimonials.classic")) {
      const testimonialSlider = new TestimonialSlider();
    }

    if (document.querySelector(".wpcf7")) {
      const form = new Form();
    }
  }
}

window.onload = function() {
  let app = new App();
};
