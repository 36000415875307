class SliderSchema {
  constructor() {
    const swiper = new Swiper(".schema-mobile", {
      centeredSlides: false,
      loop: false,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet: function(index, className) {
          const slides = document.querySelectorAll(".swiper-slide");
          let title;
          slides.forEach((slide) => {
            if (slide.getAttribute("data-swiper-slide-index") == index) {
              title = slide.querySelector(".title-h4").innerText;
            }
          });
          return '<span class="' + className + '">' + title + "</span>";
        },
      },
      on: {
        slideChange: function() {
          const courbe = document.querySelector(".courbe");
          const activeIndex = this.activeIndex;
          const translateX = -activeIndex * 55;
          courbe.style.transform = `translateX(${translateX}%)`;
        },
      },
    });
  }
}

export { SliderSchema };
