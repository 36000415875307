import "../libs/intersection-observer";

class Module13 {
  constructor() {
    let cache = {};

    let initFunc = () => {
      initCache();
      initObserver();
    };

    let initCache = () => {
      cache.modules = document.querySelectorAll(".module-13-contact");
    };

    let initObserver = () => {
      let options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.4 // Déclenchement lorsque 40% de l'élément est visible
      };

      let observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            let videos = entry.target.querySelectorAll('video');
            videos.forEach(video => {
              video.play();
            });
          }
        });
      }, options);

      cache.modules.forEach(module => {
        observer.observe(module);
      });
    };

    initFunc();
  }
}

export { Module13 };