class ChangeStyle {
    constructor() {
        let cache = {};
        let initFunc = () => {
            initCache();
            registerEvents();
        }

        let initCache = () => {
            cache.modules = document.querySelectorAll('.module-04-cartes-outline');
        };

        let registerEvents = () => {
            if (document.readyState === 'loading') {
                document.addEventListener("DOMContentLoaded", addClass);
            } else {
                addClass()
            }
        };

        let addClass = () => {
            cache.modules.forEach(module => {
                const content = module.querySelector('.card-contents');
                const cards = module.querySelectorAll('.card-content');

                if (cards.length === 6) {
                    content.classList.add('variant');
                }
            });
        }

        initFunc();
    };
}

export {ChangeStyle}
