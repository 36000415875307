class RotatingTitle {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      rotatingClass();
    };

    let initCache = () => {
      cache.wrappers = document.querySelectorAll(".words");
      cache.CURRENT_CLASS = "current";
      cache.NEXT_CLASS = "next";
      cache.FADE_OUT_CLASS = "fade-out";
    };

    const rotatingClass = () => {
      cache.wrappers.forEach((wrapper) => {
        setInterval(() => {
          const currentWord = wrapper.querySelector("span.current");
          const nextWord = wrapper.querySelector("span.next");

          if (!currentWord || !nextWord) {
            return;
          }

          const nextNextWord = nextWord.nextElementSibling
            ? nextWord.nextElementSibling
            : wrapper.firstElementChild;

          currentWord.classList.add(cache.FADE_OUT_CLASS);

          setTimeout(() => {
            currentWord.classList.remove(
              cache.CURRENT_CLASS,
              cache.FADE_OUT_CLASS
            );
          }, 700);
          nextWord.classList.remove(cache.NEXT_CLASS);
          nextWord.classList.add(cache.CURRENT_CLASS);

          if (nextNextWord && nextNextWord !== nextWord) {
            nextNextWord.classList.add(cache.NEXT_CLASS);
          } else {
            nextWord.classList.add(cache.NEXT_CLASS);
          }
        }, 2500);
      });
    };

    initFunc();
  }
}
export { RotatingTitle };
