import globalVar from "../utils/globalVar";

class Module17 {
  constructor() {
    let cache = {};

    let initFunc = () => {
      initCache();
      registerEvents();
    };

    let initCache = () => {
      cache.cardElements = document.querySelectorAll(".card-individu");
    };

    let registerEvents = () => {
      cache.cardElements.forEach((card) => {
        card.addEventListener("click", function() {
          if (!globalVar.desktop.matches) {
            this.classList.toggle("clickable");
          }
        });
      });
    };

    initFunc();
  }
}

export { Module17 };