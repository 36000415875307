import globalVar from "../utils/globalVar";

class TitleSlice {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    };

    let initCache = () => {
      cache.sectionPage = document.querySelector(".module-01-header.page");
      cache.originalSpan = document.querySelector(".title-ligne1");
      cache.wrapperTitle = document.querySelector(".title-h1.words-wrapper");
      cache.originalText = cache.originalSpan ? cache.originalSpan.textContent : '';
    };

    let registerEvents = () => {
      if (document.readyState === "loading") {
        document.addEventListener("DOMContentLoaded", handleTitleSlice);
      } else {
        handleTitleSlice();
      }

      window.addEventListener("resize", handleTitleSlice);
    };

    let handleTitleSlice = () => {
      if (globalVar.mobile.matches && cache.sectionPage) {
        sliceTitle();
      } else {
        resetTitle();
      }
      addClassTitle();
    };

    let sliceTitle = () => {
      if (!cache.originalSpan) return;

      const words = cache.originalText.split(" ");
      const groups = [];

      for (let i = 0; i < words.length; i += 2) {
        groups.push(words.slice(i, i + 2).join(" "));
      }

      cache.originalSpan.textContent = groups[0];

      let nextSibling = cache.originalSpan.nextSibling;
      while (nextSibling) {
        const current = nextSibling;
        nextSibling = nextSibling.nextSibling;
        if (current.classList && current.classList.contains("title-ligneBis")) {
          current.remove();
        }
      }

      // Référence du dernier span
      let lastInsertedSpan = cache.originalSpan;

      groups.slice(1).forEach((group) => {
        const newSpan = document.createElement("span");
        newSpan.className = "title-ligneBis";
        newSpan.textContent = group;

        lastInsertedSpan.parentNode.insertBefore(
          newSpan,
          lastInsertedSpan.nextSibling
        );

        lastInsertedSpan = newSpan;
      });
    };

    let resetTitle = () => {
      if (!cache.originalSpan) return;
      
      cache.originalSpan.textContent = cache.originalText;

      let nextSibling = cache.originalSpan.nextSibling;
      while (nextSibling) {
        const current = nextSibling;
        nextSibling = nextSibling.nextSibling;
        if (current.classList && current.classList.contains("title-ligneBis")) {
          current.remove();
        }
      }
    };

    let addClassTitle = () => {
      const hasTitleLigne1 = cache.wrapperTitle.querySelector(".title-ligne1");
      const hasTitleLigne2 = cache.wrapperTitle.querySelector(".title-ligne2");
      const hasTitleLigne2Bis = cache.wrapperTitle.querySelector(
        ".title-ligne2Bis"
      );

      if (hasTitleLigne1 && !hasTitleLigne2 && !hasTitleLigne2Bis) {
        hasTitleLigne1.classList.add("title-single-line");
      } else if (hasTitleLigne2 || hasTitleLigne2Bis) {
        cache.wrapperTitle.classList.add("title-more-line");
      }
    };

    initFunc();
  }
}

export { TitleSlice };