class PanelCards {
  constructor() {
    let cache = {};
    let currentIndex = 0;
    const intervalDuration = 5000; // Temps en millisecondes
    let intervalId;
    let startX = 0;
    let endX = 0;

    let initFunc = () => {
      initCache();
      registerEvents();
      startAutoSlide();
    };

    let initCache = () => {
      cache.titles = document.querySelectorAll(
        ".module-03-panneau-cartes .card-title"
      );
      cache.contents = document.querySelectorAll(
        ".module-03-panneau-cartes .card-content"
      );
      cache.container = document.querySelector(
        ".module-03-panneau-cartes .card-contents"
      );
    };

    let registerEvents = () => {
      if (document.readyState === "loading") {
        document.addEventListener("DOMContentLoaded", activeCards);
      } else {
        activeCards();
      }

      cache.titles.forEach((title, index) => {
        title.addEventListener("click", function() {
          clearInterval(intervalId);
          activateCard(index);
          startAutoSlide();
        });
      });

      cache.container.addEventListener("mousedown", handleStart, false);
      cache.container.addEventListener("mousemove", handleMove, false);
      cache.container.addEventListener("mouseup", handleEnd, false);

      cache.container.addEventListener("touchstart", handleStart, false);
      cache.container.addEventListener("touchmove", handleMove, false);
      cache.container.addEventListener("touchend", handleEnd, false);
    };

    let activeCards = () => {
      // Active la première carte par défaut
      if (cache.titles.length > 0) {
        cache.titles[0].classList.add("active");
        cache.contents[0].classList.add("active");
      }
    };

    let activateCard = (index) => {
      cache.titles.forEach((title) => {
        title.classList.remove("active");
      });
      cache.contents.forEach((content) => {
        content.classList.remove("active");
      });

      if (cache.titles[index]) {
        cache.titles[index].classList.add("active");
      }
      if (cache.contents[index]) {
        cache.contents[index].classList.add("active");
      }
    };

    let startAutoSlide = () => {
      intervalId = setInterval(() => {
        currentIndex = (currentIndex + 1) % cache.titles.length;
        activateCard(currentIndex);
      }, intervalDuration);
    };

    // Gérer le swipe
    let handleStart = (event) => {
      startX =
        event.type === "mousedown" ? event.clientX : event.touches[0].clientX;
    };

    let handleMove = (event) => {
      endX =
        event.type === "mousemove" ? event.clientX : event.touches[0].clientX;
    };

    let handleEnd = () => {
      if (startX && endX) {
        handleSwipe();
        startX = null;
        endX = null;
      }
    };

    let handleSwipe = () => {
      if (startX > endX + 50) {
        // Swipe vers la gauche
        currentIndex = (currentIndex + 1) % cache.titles.length;
        activateCard(currentIndex);
      } else if (startX < endX - 50) {
        // Swipe vers la droite
        currentIndex =
          (currentIndex - 1 + cache.titles.length) % cache.titles.length;
        activateCard(currentIndex);
      }
      clearInterval(intervalId);
      startAutoSlide();
    };

    initFunc();
  }
}

export { PanelCards };
